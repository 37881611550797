<template>
  <div class="MyFooter" id="MyFooter">
    <!-- 底栏容器 -->
      <div class="footer">
<!--        <div class="ng-promise-box">-->
<!--          <div class="ng-promise">-->
<!--            <ul>-->
<!--              <li><router-link to="/">首页</router-link></li>-->
<!--              <li><router-link to="/goods">全部商品</router-link></li>-->
<!--              <li><router-link to="/help/About">关于我们</router-link></li>-->
<!--              <li><router-link to="/User/order">我的订单</router-link></li>-->
<!--              <li><router-link to="/help/Service">服务中心</router-link></li>-->
<!--              <li><router-link to="/User">个人中心</router-link></li>-->
<!--            </ul>-->
<!--            <p>-->
<!--              <a>客服热线：13524136915</a>-->
<!--              <a>服务时间：工作日 9:30~12:00；13:30~18:30</a>-->
<!--              <a>官方微信小程序：巨悠租<br></a>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
        <div class="footerList">
          <div class="col">
            <div class="row first">选购指南</div>
            <div class="row item"><router-link to="/home">租赁商城</router-link></div>
            <div class="row">企业采购</div>
          </div>
          <div class="col">
            <div class="row first">设备维保</div>
          </div>
          <div class="col">
            <div class="row first">企业融资</div>
          </div>
          <div class="col">
            <div class="row first">资产管理咨询</div>
          </div>
          <div class="col">
            <div class="row first">关于我们</div>
            <div class="row item" @click="goUser('1')">个人中心</div>
            <div class="row item" @click="goUser('1-2-1')">协议说明</div>
            <div class="row item" @click="goUser('1-3-1')">租赁说明</div>
            <div class="row item" @click="goUser('4-1')">企业概况</div>
          </div>
        </div>
        <div class="replenish">
          <span>客服热线：13524136915</span>|
          <div class="qrcode">
            <span>官方微信小程序： </span>
            <img class="img" src="../assets/imgs/wxqrcode.png" />
          </div>|
          <span>服务时间：工作日 9:30~12:00；13:30~18:30</span>|
          <span>投诉热线：13100682026</span>
        </div>
        <el-divider></el-divider>
        <div class="mod_help">
          <p class="coty">Copyright©上海高瓴盛科技有限公司.版权所有 &copy; <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2022028076号-1</a></p>
        </div>
      </div>
    <!-- 底栏容器END -->
  </div>
</template>
<script>
export default {
  name: "MyFooter",
  data() {
    return {
    };
  },
  methods: {
    goUser(key) {
      this.$router.push({
        path: '/User',
        query:{key}
      })
    },
  },
  watch: {
   
  }
};
</script>
<style scoped lang="scss">
@import '../assets/css/index.css';
.footerList, .replenish{
  padding-top: 10px;
  max-width: 1230px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .col{
    font-size: 14px;
    .first{
      font-size: 18px;
    }
    .row{
      text-align: left;
      margin-bottom: 15px;
    }
    .item{
      cursor: pointer;
    }
  }
}
.replenish{
  padding: 0;
  align-items: center;
  font-size: 18px;
  .qrcode{
    display: flex;
    align-items: center;
    .img{
      width: 100px;
      height: 100px;
    }
  }

}
</style>