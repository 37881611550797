import Axios from 'axios'
// 确认手机号
export const affirmMobile = (data) => Axios({
    url: '/zuwu-api/web/userInfoPort/affirmMobile',
    method: 'post',
    dataType: 'json',
    data
});

// 官网-用户资料详情
export const userDetail = (params) => Axios({
    url: '/zuwu-api/web/userInfoPort/detail',
    method: 'get',
    params
});
// 官网-保存用户补充资料
export const saveSupplementaryInfo = (data) => Axios({
    url: '/zuwu-api/web/userInfoPort/saveSupplementaryInfo',
    method: 'post',
    dataType: 'json',
    data
});
// 官网-企业添加补充信息
export const addSupplementInfo = (data) => Axios({
    url: '/zuwu-api/web/enterprise/addSupplementInfo',
    method: 'post',
    dataType: 'json',
    data
});
// 官网-企业信息详情
export const enterpriseDetails = (data) => Axios({
    url: '/zuwu-api/web/enterprise/details',
    method: 'post',
    dataType: 'json',
    data
});

// 更换绑定手机号
export const updateMobile = (data) => Axios({
    url: '/zuwu-api/web/userInfoPort/updateMobile',
    method: 'post',
    dataType: 'json',
    data
});
// 官网-用户名修改
export const updateName = (params) => Axios({
    url: '/zuwu-api/web/userInfoPort/updateName',
    method: 'get',
    params
});
// 官网-账户密码修改
export const updatePassword = (data) => Axios({
    url: '/zuwu-api/web/userInfoPort/updatePassword',
    method: 'post',
    dataType: 'json',
    data
});
// 官网-个人信息
export const userName = (data) => Axios({
    url: '/zuwu-api/web/userInfoPort/userName',
    method: 'post',
    dataType: 'json',
    data
});
// 个人中心 — 订单数量
export const orderNumber = (params) => Axios({
    url: '/zuwu-api/web/orderInfo/orderNumber',
    method: 'post',
    params
});
// 用户获取更换手机号短信验证码
export const verCode = (data) => Axios({
    url: '/zuwu-api/web/userInfoPort/verCode',
    method: 'post',
    dataType: 'json',
    data
});
// 我的账户 - 企业信息列表
export const getEnterpriseInfoList = (params) => Axios({
    url: '/zuwu-api/web/enterprise/getEnterpriseInfoList',
    method: 'get',
    params
});
// 上传文件
export const putFile = (data) => Axios({
    url: '/zuwu-api/file/put-file',
    method: 'post',
    timeout: 30000,
    headers: { "content-type": "multipart/form-data;charset=UTF-8" },
    data
});

