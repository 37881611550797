<template>
<div class="newHeader" id="newHeader">
  <div class="content">
    <div class="left">
      <div class="item">
        <router-link class="big" to="/">高享汇</router-link>
      </div>
      <div class="item">
        <router-link to="/home">租赁商城</router-link>
      </div>
      <div class="item">企业采购</div>
      <div class="item">设备维保</div>
      <div class="item">资产咨询管理</div>
      <div class="item">
        <router-link to="/enterpriseAssistance">企业融资</router-link>
      </div>
    </div>
    <div class="right">
      <el-dropdown class="dropdown">
        <span class="el-dropdown-link item">
          个人中心<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <p @click="goUserInfo('1')">用户中心</p>
          </el-dropdown-item>
          <el-dropdown-item >
            <p @click="goUserInfo('2-1')">订单中心</p>
          </el-dropdown-item>
<!--          <el-dropdown-item>-->
<!--            <router-link to="/help/Service">服务中心</router-link>-->
<!--          </el-dropdown-item>-->
          <el-dropdown-item >
            <p @click="goUserInfo('4-1')"> 企业概况</p>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="loginUot item" v-if="this.$store.getters.getUser" @click="logout">退出登录</div>
      <div class="login item" @click="login" v-else>登录/注册</div>
    </div>
  </div>
</div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name:'newMyHeader',
  data(){
    return{

    }
  },
  methods:{
    ...mapActions(["setUser","setToken" ]),
    logout() {
      this.$confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.visible = false;
        // 清空本地登录信息
        localStorage.setItem("user", "");
        localStorage.setItem("token", "");
        // 清空vuex登录信息
        this.setUser("");
        this.setToken("");
        this.$router.push({ path: '/login' })
      }).catch(() => {

      });
    },
    login(){
      this.$router.push({ path: '/login' })
    },
    goUserInfo(key){
      this.$router.push({
        path:'/User',
        query:{key}
      })
    }
  }
}
</script>

<style scoped lang="scss">
.newHeader{
  background: #1b1f23;
  color: #ffffff;
  font-size: 16px;
  line-height: 50px;
  .content{
    max-width: 1430px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right ,.left{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item{
        cursor: pointer;
        a{
          color: #fff !important;
        }
        .big{
          font-size: 26px;
        }
      }
    }
    .right{
      .dropdown{
        color: #ffffff;
        font-size: 16px;
        margin-right: 20px;
      }
    }
    .left{
      width: 550px;
    }
  }
}
</style>