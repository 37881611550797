<template>
  <div class="upload-item" id="UploadItem">
    <div class="flex-div uploaditem">
      <div  class="item-file">
          <div class="item" v-for="(url, index) in fileList" :key="index" >
              <i class="el-icon-close" @click="handleClose(index)"></i>
              <i class="el-icon-view" @click="downloadlink(url)" ></i>
              <template v-if="isImage(url)">
                <img :src="url">
              </template>
              <template v-else-if="isVideo(url)">
                <video width="200" height="150" controls>
                  <source :src="url" type="video/mp4">
                  <source :src="url" type="video/ogg">
                </video>
              </template>
              <template v-else-if="isPDF(url)">
                 <img src="../assets/imgs/pdf-ico.png">
              </template>
              <template v-else-if="isDOC(url)">
                <img src="../assets/imgs/doc-ico.png">
              </template>
              <template v-else>
                链接{{index}}
              </template>
          </div>
      </div>
      <el-upload class="upload-demo" action  drag :http-request="uploadFile" ref="upload"
                 :on-exceed="handleExceed" :before-upload="beforeUpload" :show-file-list="false" :headers="headers">
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
  </div>
</template>

<script>
import {putFile} from "@/api/userInfoPort";
export default {
  name: 'UploadItem',
  props:['fileList'],
  data() {
    return {
      //上传后的文件列表
      // fileList: [],
      // 允许的文件类型
      fileType: [ "pdf", "doc", "docx", "xls", "xlsx","txt","png","jpg", "bmp", "jpeg", "mp4"],
      // 运行上传文件大小，单位 M
      fileSize: 50,
      //请求头
      headers: { "Content-Type": "multipart/form-data" },
    }
  },
  created(){},
  methods: {
    
    isImage(url) {
      return url.toLowerCase().endsWith('.jpg') || url.toLowerCase().endsWith('.png') || url.toLowerCase().endsWith('.jpeg');
    },
    isVideo(url) {
      return url.toLowerCase().endsWith('.mp4');
    },
    isPDF(url) {
      return url.toLowerCase().endsWith('.pdf');
    },
    isDOC(url) {
      return url.toLowerCase().endsWith('.doc');
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != '' || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, '').toLowerCase()
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50 //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$showMessage('上传文件大小不能超过 50MB!')
          return false
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true
        } else {
          this.$message({
            message: '上传文件格式不正确',
            type: 'error'
          });
          return false
        }
      }
    },
    //自定义的移除事件
    handleClose(i) {
      // this.fileList.splice(i, 1) //删除上传的文件
      this.$emit("ondelete", i);
      // if (this.fileList.length == 0) {
      //   //如果删完了
      //   this.fileflag = true //显示url必填的标识
      // }
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！'
      })
      return
    },
    //上传文件的事件
    uploadFile(item) {
      this.$notify.info({
        title: '上传中',
        message: '文件正在上传中'
      });
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      putFile(FormDatas).then((res) => {
        if (res.data.code == 200) {
          // this.fileList.push(item.file) //成功过后手动将文件添加到展示列表里
          // let i = this.fileList.indexOf(item.file)
          // this.fileList[i].link = res.data.data.link 
          this.$emit("handleChange", res.data.data.link);
          this.$notify({
            title: '成功',
            message: '文件上传成功',
            type: 'success'
          });
          // if (this.fileList.length > 0) {
          //   //如果上传了附件就把校验规则给干掉
          //   this.fileflag = false
          // }
          //this.handleSuccess();
        }else{
          this.$notify({
            title: '错误',
            message: '文件上传错误',
            type: 'warning'
          });
        }
      })
    },
    downloadlink(e){
      window.open(e, '_blank');
    },
    //上传成功后的回调
    handleSuccess() {}
  }
}
</script>
<style scoped lang="scss">
.uploaditem{
  padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .upload-demo{
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .avatar-uploader-icon{
      font-size: 38px;
      color: #8c939d;
      width: 150px;
      height: 150px;
      line-height: 150px;
      text-align: center;
    }
    ::v-deep .el-upload-dragger{
      width: 150px;
      height: 150px;
    }
  }
  .item-file{
    display: flex;
    align-items: center;
    .item{
      width: 150px;
      height: 150px;
      margin-right: 20px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        left: 5px;
        top: 5px;
        display: none;
      }
      .el-icon-view{
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
        display: none;
      }
    }
    .item:hover{
      .el-icon-close ,.el-icon-view{
        display: block;
      }
    }
  }
}
</style>