<template>
  <div class="MyHeader" id="MyHeader">
    <!-- 顶栏容器 -->
    
      <div class="header_w">
        <div class="topbar">
          <div class="nav">
            <ul>
              <div v-if="!this.$store.getters.getUser">
                <li >欢迎来到巨悠租
                  <el-button type="text" @click="login">登录</el-button>
                  <span class="sep">|</span>
                  <el-button type="text" @click="Register">注册</el-button>
                </li>
              </div>
              <div class="right">
                <li v-if="this.$store.getters.getUser">
                  您好，欢迎{{ this.$store.getters.getUser }}来到巨悠租 <el-button type="text" @click="logout">退出</el-button>
                </li>
                <li>
                  <router-link to="/User/order">我的订单</router-link>
                </li>
                <li>
                  <router-link to="/help/Service">服务中心</router-link>
                </li>
                <li :class="getNum > 0 ? 'shopCart-full' : 'shopCart'">
                  <router-link to="/User">
                    <i class="el-icon-user-solid"></i>
                    个人中心
                  </router-link>
                </li>
              </div>

            </ul>
          </div>
        </div>
      </div>
      <div style="height: 40px;"></div>
    <!-- 顶栏容器END -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: 'MyHeader',
  data() {
    return {
      activeIndex: '', // 头部导航栏选中的标签
      getNum: 0
    }
  },
  beforeUpdate() {
    this.activeIndex = this.$route.path
  },
  methods: {
    ...mapActions(["setUser","setToken","removeStore" ]),
   
    logout() {
      this.$confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.visible = false;
        this.removeStore()
        this.$router.push({ path: '/' })
      }).catch(() => {
         
      });
    },
   
   
    login() {
      this.$router.push({ path: '/login' })
    },

    Register() {
      this.$router.push({ path: '/login' })
    },
    
  },
  watch: {}
}
</script>
<style>
@import '../assets/css/index.css';
</style>