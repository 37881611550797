<template>
  <div id="myaddress" class="myaddress">
    <div class="address-body">
      <ul>
        <li
          :class="item.id == confirmAddress ? 'in-section' : 'in-section'"
          v-for="item in address"
          :key="item.id"
        >
          <h2>{{item.deliveryName}}</h2>
          <p class="phone">{{item.deliveryPhone}}</p>
          <p class="address">{{item.province}}{{item.city}}{{item.area}}{{item.detailAddress}}</p>
          <p class="modification" @click="editAddress(item)"><i class="el-icon-edit"></i></p>
        </li>
        <li class="add-address" @click="addAddress" v-if="address.length == 0">
          <i class="el-icon-circle-plus-outline"></i>
          <p>添加新地址</p>
        </li>
      </ul>
    </div>

    <el-dialog
        title="收货地址"
        :visible.sync="editShow"
        width="500px"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <el-form
          :model="formEdit"
          ref="formEdit"
          size="small"
          label-width="100px"
        >
          <el-form-item
            label="收货人姓名"
            prop="deliveryName"
            :rules="{
              required: true,
              message: '请输入收货人姓名',
              trigger: 'blur'
            }"
          >
            <el-input
              v-model="formEdit.deliveryName"
              placeholder="请输入收货人姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号码"
            prop="deliveryPhone"
            :rules="{
              required: true,
              message: '请输入手机号码',
              trigger: 'blur'
            }"
          >
            <el-input
              v-model="formEdit.deliveryPhone"
              placeholder="请输入手机号码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="省/市/区"
            prop="provinceData"
            :rules="{
              required: true,
              message: '请选择省/市/区',
              trigger: 'change'
            }"
          >
            <el-cascader
              v-model="formEdit.provinceData"
              :options="area"
              placeholder="请选择省/市/区"
              clearable
              style="width: 100%"
              ref="cascaderArr" 
              @change="handleChange($event)" 
            >
          </el-cascader>
          </el-form-item>
          <el-form-item
            label="详细地址"
            prop="detailAddress"
            :rules="{
              required: true,
              message: '请输入详细地址',
              trigger: 'blur'
            }"
          >
            <el-input
              type="textarea"
              :rows="3"
              clearable
              placeholder="请输入内容"
              v-model="formEdit.detailAddress"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="submit">
          <el-button type="primary" size="medium" @click="submitEdit">确 定</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import area from "../util/area.js";
export default {
  name: "myaddress",
  props: [],
  data() {
    return {
      area,
      confirmAddress:0,
      editShow:false,
      formEdit: {
        userId: '',
        provinceData: [],
        provinceCode: '',
        cityCode: '',
        areaCode: '',
        detailAddress: '',
        orderId: '',
        deliveryName: '',
        deliveryPhone: ''
      },
      address: [],
    };
  },
  computed: {

  },
  created(){
    this.getUserAddressList()
    
  },
  methods: {
    handleChange() {
        const checkedNode =  this.$refs["cascaderArr"].getCheckedNodes();
       this.formEdit.checkedNode = checkedNode[0].pathLabels
    },
    // 获取地址
    getUserAddressList(){
      this.$axios
      .get("/zuwu-api/web/orderdeliveryaddress/list", {})
      .then(res => {
        this.address = res.data.data
        this.$emit("addressnum",this.address.length)
      })
      .catch(err => {
        return Promise.reject(err);
      });
    },
    // 地址选择
    onAddress(val){
      this.confirmAddress = val
    },
    // 编辑地址
    editAddress(val){
      for (var i in this.formEdit) {
        this.formEdit[i] = "";
      }
      this.formEdit.id = val.id
      this.editShow = true
    },
    // 添加地址
    addAddress(){
      for (var i in this.formEdit) {
        this.formEdit[i] = "";
      }
      this.editShow = true
    },
    // 确定提交
    submitEdit() {
      this.$refs.formEdit.validate(valid => {
        if (valid) {
          this.$confirm("是否确定提交修改?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(async () => {
            if (this.formEdit.checkedNode && this.formEdit.checkedNode.length == 3) {
              this.formEdit.province = this.formEdit.checkedNode[0]
              this.formEdit.city = this.formEdit.checkedNode[1]
              this.formEdit.area = this.formEdit.checkedNode[2]
            }
            this.$axios
              .post('/zuwu-api/web/orderdeliveryaddress/save', this.formEdit)
              .then((res) => {
                if (res.data.code == 200) {
                  this.$message({
                    type: "success",
                    message: "操作成功!"
                  });
                  this.getUserAddressList()
                  this.editShow = false;
                  for (var i in this.formEdit) {
                    this.formEdit[i] = "";
                  }
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg
                  });
                }
              })
              .catch((err) => {
                return Promise.reject(err)
              })
          });
        }
      });
    },
  }
};
</script>
<style scoped>
.address-body{
  width: 100%;
  overflow: hidden;
}
.address-body li {
  float: left;
  color: #333;
  width: 200px;
  border: 2px solid #e0e0e0;
  padding: 10px 20px 0;
  margin-right: 15px;
  margin-bottom: 24px;
  position: relative;
}
.address-body .in-section {
  border: 2px solid #1E95D4;
  background: #f7fcff;
}
.address-body li h2 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
}
.address-body li p {
  font-size: 14px;
  color: #757575;
}
.address-body li .address {
  padding: 10px 0;
}
.address-body .add-address {
  text-align: center;
  line-height: 30px;
}
.address-body .add-address i {
  font-size: 30px;
  padding-top: 30px;
  text-align: center;
}
.address-body .modification{
  position: absolute;
  top: 5px;
  font-size: 20px;
  cursor: pointer;
  right: 10px;
}
.address-body .modification:hover{
  color: #1E95D4;
}
.submit{
  text-align: right;
}
</style>