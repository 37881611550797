<template>
  <div id="app" name="app">
    
    <el-container>
      <!-- 主要区域容器 -->
      <el-main>
        <router-view></router-view>
      </el-main>
      <!-- 主要区域容器END -->
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  
  data() {
    return {
      register: false, // 是否显示注册组件
      visible: false // 是否退出登录
    };
  },
  created() {
    // 获取浏览器localStorage，判断用户是否已经登录
    if (localStorage.getItem("user")) {
      // 如果已经登录，设置vuex登录状态
      this.setUser(localStorage.getItem("user"));
    }
    if (localStorage.getItem("currentCommodityDetail")) {
      // 如果已经登录，设置vuex登录状态
      this.setcurrentCommodityDetail(localStorage.getItem("currentCommodityDetail"));
    }
  },
  computed: {
    ...mapGetters(["getUser", "getcurrentCommodityDetail",])
  },
  watch: {
    // 获取vuex的登录状态
  //   getUser: function(val) {
  //     if (val === "") {
  //       // 用户没有登录
  //       this.setShoppingCart([]);
  //     } else {
  //       // 用户已经登录,获取该用户的购物车信息
  //       this.$axios
  //         .post("/api/user/shoppingCart/getShoppingCart", {
  //           user_id: val.user_id
  //         })
  //         .then(res => {
  //           if (res.data.code === "001") {
  //             // 001 为成功, 更新vuex购物车状态
  //             this.setShoppingCart(res.data.shoppingCartData);
  //           } else {
  //             // 提示失败信息
  //             this.notifyError(res.data.msg);
  //           }
  //         })
  //         .catch(err => {
  //           return Promise.reject(err);
  //         });
  //     }
  //   }
  },
  methods: {
    ...mapActions(["setUser",  "setShoppingCart",'setcurrentCommodityDetail']),
    
    
  }
};
</script>

<style>
@import "./assets/css/index.css";

</style>