<template>
  <div class="upload-img" id="UploadImg">
    <el-upload action drag class="avatar-uploader" :http-request="uploadFile" :show-file-list="false" :disabled="disabled">
      <img v-if="imagelink" :src="imagelink" class="avatar" />
      <i v-else class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'UploadImg',
  props:['imageUrl','disabled'],
  data() {
    return {
      imagelink:this.imageUrl
    }
  },
  methods: {
    //上传文件的事件
    uploadFile(item) {
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData()
      FormDatas.append('file', item.file)
      this.$axios({
        method: 'post',
        url: '/zuwu-api/file/put-file',
        headers: this.headers,
        timeout: 30000,
        data: FormDatas
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.imagelink = res.data.data.link
            this.$emit('handleChange', res.data.data.link)
          } else {
            this.$notify({
              title: '错误',
              message: '文件上传错误',
              type: 'warning',
            })
          }
        })
    },
    //上传成功后的回调
    handleSuccess() {}
  }
}
</script>
<style scoped>
.upload-img /deep/ .el-upload {width: 100%; line-height: 180px;}
.avatar-uploader .el-upload:hover {  border-color: #409eff;}
.upload-img /deep/ .el-upload-dragger {  width: 100%;}
.avatar{ width: 100%; height: 100%;}
</style>