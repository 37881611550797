<template>
  <div class="MyMenu" id="MyMenu">
    <div class="other_header" v-if="menuname == 'login'">
      <router-link to="/">
       高享汇
      </router-link>
      <ul>
        <li>售前咨询： 15021648934</li>
        <!--        <li>联系客服</li>-->
        <!--        <li>服务中心</li>-->
        <li class="user" @click="goUser">用户协议</li>
      </ul>
    </div>
    <div class="other_header" v-if="menuname == 'other'">
      <router-link to="/home">
        <img src="../assets/imgs/juzlogo.png" alt />
      </router-link>
      <ul>
        <li>售前咨询： 15021648934</li>
        <li class="user" @click="goUser">用户协议</li>
      </ul>
    </div>
    <div class="other_header" v-if="menuname == 'place'">
      <router-link to="/home">
        <img src="../assets/imgs/juzlogo.png" alt />
      </router-link>
      <div class="step">
        <el-steps :active="stepIndex" finish-status="success" align-center>
          <el-step title="确认订单"></el-step>
          <el-step v-if="!isAuth" title="实名认证"></el-step>
          <el-step title="支付/签约"></el-step>
          <el-step title="补充资料"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="index_header" v-if="menuname == 'index'">
      <div>
        <div class="logo">
          <router-link to="/home">
            <img src="../assets/imgs/juzlogo.png" alt />
          </router-link>
        </div>
        <!--        <el-menu-item index="/home">首页</el-menu-item>-->
        <!--        <el-menu-item index="/goods">全部商品</el-menu-item>-->
        <!--        <el-menu-item index="/help/About">关于我们</el-menu-item>-->
        <div class="so">
          <el-input placeholder="请输入搜索内容" v-model="search">
            <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="index_header" v-if="menuname == 'user'">
      <el-menu :default-active="activeIndex" class="el-menu-demo" active-text-color="#409eff" router>
        <div class="logo">
          <router-link to="/home">
            <img src="../assets/imgs/juzlogo.png" alt />
          </router-link>
          <h2>个人中心</h2>
        </div>
        <div class="so">
          <el-input placeholder="请输入搜索内容" v-model="search">
            <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
          </el-input>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyMenu',
  props: ['menuname','stepIndex','isAuth'],
  data() {
    return {
      activeIndex: '', // 头部导航栏选中的标签
      search: '', // 搜索条件
      getNum: 0
    }
  },
  beforeUpdate() {},
  created() {
    this.activeIndex = this.$route.path
  },
  watch: {
    $route(to) {
      let { path } = to
      this.activeIndex = path
    }
  },
  methods: {
    goUser() {
      this.$router.push({
        path: '/User',
        query:{key:'1-2-1'}
      })
    },
    // 点击搜索按钮
    searchClick() {
      if (this.search != '') {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: '/goods', query: { search: this.search } })
        this.search = ''
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '../assets/css/index.css';
.MyMenu{
  background: #fff;
  margin: 0 auto;
  .other_header{
    a{
      font-size: 30px;
      color: #6a737d;
    }
    .user{
      cursor: pointer;
    }
    .step{
      width: 40%;
      float: right;
    }
  }
}
</style>
